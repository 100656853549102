<template>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top" style="border-bottom: 1px solid #EFEFEF; background-color: #FFFFFF;">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <!--<img src="@/assets/logo.png" alt="" width="30" height="24" class="d-inline-block align-text-top">-->
        {{ userName }}
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="#" @click="logout">Abmelden</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import {getAuth, signOut} from "firebase/auth";
import {isAdmin} from '@/firebase'

export default {
  name: "Nav",
  methods: {
    logout() {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.$router.replace('/login')
      }).catch((error) => {
        console.log(error);
      });
    },
    isUserAdmin() {
      console.log(isAdmin())
      return isAdmin()
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    userName() {
      return this.$store.state.userName;
    }
  }
}
</script>
