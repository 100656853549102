<template>
    <Nav />
    <main role="main">
      <div class="container-sm" style="margin-top: 80px;">
        <router-view />
      </div>
    </main>
</template>

<script>
import Nav from "@/components/Nav.vue";

export default {
  name: "UserLayout",
  components: {Nav},
  mounted() {
    this.$store.dispatch("setUserName");
  }
}
</script>
